<template>
  <!-- module widget list -->
  <div class="module-widget-list relative" ref="wrapper">
    <h3 :class="titleClass">{{ title }}</h3>
    <div class="module-list -mt-8 mb-20 relative">
      <swiper :dots="true">
        <template v-for="module in modules">
          <router-link
              custom
              v-if="!module.isPaid"
              :key="module.id"
              v-slot="{ navigate }"
              :to="{ name: $routeNames.ModulePage, params: { slug: module.slug }}">
            <module-widget :key="module.id"
                           :bg="bg"
                           :hour-bg="hourBg"
                           @open="navigate"
                           class="w-352px h-409px flex-shrink-0 mr-6"
                           :module="module"></module-widget>
          </router-link>
          <router-link
              custom
              v-if="module.isPaid"
              :key="module.id"
              :to="{ name: $routeNames.AccountModuleDetail, params: { id: module.id }}">
            <module-minimal-widget :bg="bg" :hour-bg="hourBg"
                                   :key="module.id"
                                   class="w-352px h-409px flex-shrink-0 mr-6" :module="module"></module-minimal-widget>
          </router-link>
        </template>
      </swiper>
    </div>
  </div>
  <!-- end of module widget list -->
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ModuleWidget from '../module-widget/module-widget.vue';
import ModuleMinimalWidget from '../module-widget/module-minimal-widget.vue';
import Swiper from '../swiper/swiper.vue';

@Component({
  name: 'module-list',
  components: { Swiper, ModuleMinimalWidget, ModuleWidget },
  props: {
    title: {
      type: String,
      required: true,
    },
    modules: {
      type: Array,
      required: true,
    },
    titleClass: {
      type: String,
      default: 'text-white border-l-4 border-white pl-2 uppercase mt-6 text-xl leading-5 font-bold',
    },
    bg: {
      type: String,
      default: 'bg-primary-800',
    },
    hourBg: {
      type: String,
      default: 'bg-primary-900',
    },
  },
})
export default class ModuleList extends Vue {
}
</script>

<style scoped lang="scss">
$gradientSize: 0;
.module-widget-list {
  .module-list {
    position: relative;
    height: 417px;
    padding-left: $gradientSize;

    &::after, &::before {
      content: '';
      position: absolute;
      height: inherit;
      width: $gradientSize;
      top: 0;
      z-index: 10;
      background-image: linear-gradient(to left, var(--tw-gradient-from), var(--tw-gradient-to));
    }

    &::after {
      right: -$gradientSize;
      --tw-gradient-to: transparent;
      --tw-gradient-from: #16223A;
    }

    &::before {
      left: -$gradientSize;
      --tw-gradient-from: transparent;
      --tw-gradient-to: #16223A;
    }

    .module-list-container {
      //margin: 0 -$gradientSize 0 -$gradientSize;
      margin-left: -$gradientSize;
      margin-right: -$gradientSize;
      padding: 4px;
      box-sizing: border-box;
    }
  }
}
</style>
