<template>
  <div class="swiper-container">
    <div class="swiper-controls">
      <div class="arrow a-right" @click="scrollRight" v-if="hasNextIndex">
        <svg xmlns="http://www.w3.org/2000/svg" width="9.827" height="17.655" viewBox="0 0 9.827 17.655">
          <path data-v-34b1e000="" id="Path_249" data-name="Path 249" d="M0,7.413,7.413,0l7.413,7.413"
                transform="translate(8.827 1.414) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2"></path>
        </svg>
      </div>
      <div class="arrow a-left" @click="scrollLeft" v-if="hasPrevIndex">
        <svg class="transform rotate-180" xmlns="http://www.w3.org/2000/svg" width="9.827" height="17.655"
             viewBox="0 0 9.827 17.655">
          <path data-v-34b1e000="" id="Path_249" data-name="Path 249" d="M0,7.413,7.413,0l7.413,7.413"
                transform="translate(8.827 1.414) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round"
                stroke-linejoin="round" stroke-width="2"></path>
        </svg>
      </div>
    </div>
    <div class="scroll-layout" ref="container">
      <div class="scroll-rail">
        <slot/>
      </div>
    </div>
    <div class="dots-controls" v-if="dots">
      <div class="dot-item" v-for="(dot, index) in localDots" :key="versioning + '-' + index" @click="handleClickDot(index)"
           :class="{selected: isVisible(index)}"></div>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import swipe from '../../_helpers/swipe-detector';

@Component({
  name: 'swiper',
  props: {
    dots: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hasNextIndex: false,
      hasPrevIndex: false,
      versioning: 0,
      localDots: [],
    };
  },
  methods: {
    handleClickDot(index) {
      if (this.isVisible(index)) return;
      const elementsBounds = this.getElementsBounds();
      let tmpScroll = 0;
      for (let i = 0; i < index; i += 1) {
        if (this.isVisibleForScroll(index, tmpScroll)) {
          this.scrollToIndex(this.adjustIndex(i));
          return;
        }
        tmpScroll = elementsBounds[i].start + elementsBounds[i].width;
      }
      this.scrollToIndex(this.adjustIndex(index));
    },
    isVisibleForScroll(index, currentScroll) {
      if (!this.$refs.container) return false;
      const elementsBounds = this.getElementsBounds();
      const parentWidth = this.$refs.container.offsetWidth;
      if (!elementsBounds[index]) return false;
      const { start, width } = elementsBounds[index];
      if (start < currentScroll) return false;
      if ((start + width) > (currentScroll + parentWidth)) return false;
      return true;
    },
    isVisible(index) {
      return this.isVisibleForScroll(index, this.getCurrentScroll());
    },
    scrollRight() {
      this.scroll('right');
    },
    scrollLeft() {
      this.scroll('left');
    },
    getCurrentScroll() {
      if (!this.$refs.container) return 0;
      const rails = this.$refs.container.querySelector('.scroll-rail');
      const style = window.getComputedStyle(rails);
      // eslint-disable-next-line no-undef
      const matrix = new WebKitCSSMatrix(style.transform);
      return matrix.m41 * -1;
    },
    getElementsBounds() {
      if (!this.$refs.container) return [];
      return Array.from(this.$refs.container.querySelector('.scroll-rail').children).map((el) => ({
        start: el.offsetLeft,
        width: el.offsetWidth,
      }));
    },
    getCurrentIndex() {
      let index = 0;
      const elementBounds = this.getElementsBounds();
      const currentScroll = this.getCurrentScroll();
      // eslint-disable-next-line no-restricted-syntax
      for (const bounds of elementBounds) {
        if (bounds.start < currentScroll) index += 1;
      }
      return this.adjustIndex(index);
    },
    adjustIndex(index) {
      const elementBounds = this.getElementsBounds();
      return Math.min(elementBounds.length - 1, Math.max(0, index));
    },
    scroll(direction) {
      if (!this.$refs.container) return;
      const index = this.adjustIndex(this.getCurrentIndex() + (direction === 'right' ? 1 : -1));
      this.scrollToIndex(index);
    },
    scrollToIndex(index) {
      const elementsBounds = this.getElementsBounds();
      const rails = this.$refs.container.querySelector('.scroll-rail');
      const currentScroll = this.getCurrentScroll();
      rails.animate([
        { transform: `translateX(${-currentScroll}px)` },
        { transform: `translateX(${-Math.round(elementsBounds[index].start - elementsBounds[0].start)}px)` },
      ], {
        duration: 150,
        fill: 'forwards',
      });
      setTimeout(() => {
        this.updateArrows();
        this.versioning += 1;
        this.versioning %= 20;
      }, 200);
    },
    updateArrows() {
      if (!this.$refs.container) return;
      const elementsBounds = this.getElementsBounds();
      const currentScroll = this.getCurrentScroll();
      const railWidth = elementsBounds[elementsBounds.length - 1].start + elementsBounds[elementsBounds.length - 1].width;
      const parentWidth = this.$refs.container.offsetWidth;
      this.hasPrevIndex = currentScroll > 0;
      this.hasNextIndex = railWidth > (currentScroll + parentWidth);
    },
  },
  mounted() {
    // TODO gérer le resize
    swipe.swipeDetector(this.$refs.container, {
      detectTop: false,
      detectBottom: false,
      prevent: false,
    });
    this.$refs.container.addEventListener('element:swipe', (e) => {
      if (e.detail !== 'left' && e.detail !== 'right') return;
      this.scroll(e.detail);
    });
    this.updateArrows();
    this.localDots = Array.from(this.$refs.container.querySelector('.scroll-rail').children);
  },
})
export default class Swiper extends Vue {
}
</script>

<style scoped>
</style>
