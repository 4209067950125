<template>
  <!-- module widget -->
  <div class="relative module-widget rounded-3xl overflow-hidden flex flex-col relative cursor-pointer"
       :class="bg"
       role="link"
       @click="handleClick">
    <div class="module-picture-container">
      <div class="module-picture" :style="modulePictureStyle"></div>
    </div>

    <module-level :module="module"></module-level>
    <div class="content-container pt-8 pb-4 px-6 text-white flex flex-col flex-grow">
      <div class="card-title text-2xl leading-6 clamp-2">{{ module.title }}</div>
      <module-meta :resources-count="module.resourcesCount" :hour-bg="hourBg" :hours="module.hours"></module-meta>
      <module-price :module="module" class="m-auto mr-0 mb-0" v-if="price > 0"></module-price>
      <div v-else class="already-buy-area">
        <div class="already-buy">
          Gratuit
        </div>
      </div>
    </div>
  </div>
  <!-- end of module widget -->
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import ModuleInterface from '@/services/interface/entities/module.interface';
import './module-widget.scss';

@Component({
  name: 'module-widget',
  components: {
    ModuleMeta: () => import(/* webpackPrefetch: true */ './module-meta.vue'),
    ModulePrice: () => import(/* webpackPrefetch: true */ './module-price.vue'),
    ModuleLevel: () => import(/* webpackPrefetch: true */ '@/components/module-widget/module-level.vue'),
  },
  props: {
    module: {
      type: ModuleInterface,
      required: true,
    },
    bg: {
      type: String,
      default: 'bg-primary-800',
    },
    hourBg: {
      type: String,
      default: 'bg-primary-900',
    },
  },
  computed: {
    modulePictureStyle() {
      return `background-image: url(${this.module.picture.getPath({ w: 700, h: 400, crop: true })})`;
    },
    price() {
      return this.module.price;
    },
  },
  methods: {
    async handleClick(e) {
      this.$emit('open', e);
    },
  },
})
export default class ModuleWidget extends Vue {
}
</script>

<style scoped>

</style>
