function getTouches(evt) {
  return evt.touches || evt.originalEvent.touches;
}

function handleTouchStart(element, evt) {
  const firstTouch = getTouches(evt)[0];
  // eslint-disable-next-line no-param-reassign
  element.dataset.xDown = firstTouch.clientX;
  // eslint-disable-next-line no-param-reassign
  element.dataset.yDown = firstTouch.clientY;
}

function prepareSwipe(config, element, direction) {
  if (!config[`detect${direction.slice(0, 1).toUpperCase()}${direction.slice(1).toLowerCase()}`]) return;
  // eslint-disable-next-line no-param-reassign
  element.dataset.swipeDirection = direction;
}

function dispatchSwipe(element) {
  if (!element.dataset.swipeDirection) return;
  element.dispatchEvent(new CustomEvent('element:swipe', { detail: element.dataset.swipeDirection }));
}

function handleTouchMove(element, evt, config) {
  if (!element.dataset.xDown || !element.dataset.yDown) {
    return;
  }

  const xUp = evt.touches[0].clientX;
  const yUp = evt.touches[0].clientY;

  const xDiff = element.dataset.xDown - xUp;
  const yDiff = element.dataset.yDown - yUp;

  if (Math.abs(xDiff) < config.precision && Math.abs(yDiff) < config.precision) return;
  if (Math.abs(xDiff) > Math.abs(yDiff)) {
    if (xDiff > 0) prepareSwipe(config, element, 'right');
    else prepareSwipe(config, element, 'left');
  } else if (yDiff > 0) prepareSwipe(config, element, 'bottom');
  else prepareSwipe(config, element, 'top');
}

function swipeDetector(element, options) {
  const config = {
    detectRight: true,
    detectLeft: true,
    detectTop: true,
    detectBottom: true,
    prevent: true,
    precision: 20,
    ...options,
  };

  element.addEventListener('touchstart', (event) => {
    if (config.prevent) event.preventDefault();
    handleTouchStart(element, event);
  }, { passive: true });

  element.addEventListener('touchend', (event) => {
    if (config.prevent) event.preventDefault();
    dispatchSwipe(element);
  }, { passive: true });

  element.addEventListener('touchmove', (event) => {
    if (config.prevent) event.preventDefault();
    handleTouchMove(element, event, config);
  }, { passive: true });
}

function removeDetector(element) {
  element.removeAllListeners();
}

export default { swipeDetector, removeDetector };
